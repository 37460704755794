import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import { getCoupons } from '../../redux/actions-exporter'
import Coupon from './_components/_coupon'

import logo from '../../assets/images/ICL_logo.svg'

const CouponsList = ({ couponsSlidesPerView }) => {
    const dispatch = useDispatch()
    // const coupons = useSelector(state => state.coupon.coupons)
    const couponsArr = [
        {
            title: "הזמן כרטיס אשראי",
            subTitle: "וקבל מתנה שווה!",
            img: logo,
            color: "var(--primary)",
            bg: "var(--lightgray)"
        },
        // {
        //     title: "NINJA GRILL AG301",
        //     subTitle: 'גריל שצולה אופה ומטגן',
        //     desc: '749 ש"ח במקום 1100 ש"ח',
        //     img: ninja,
        //     color: "var(--primary)",
        //     bg: "var(--secondary)"
        // },
        // {
        //     title: "Dyson V11 Absolute",
        //     subTitle: 'שואב אבק עומד',
        //     desc: '1799 ש"ח במקום 2199 ש"ח',
        //     img: dyson,
        //     color: "white",
        //     bg: "var(--bg)"
        // },
        // {
        //     title: 'מצלמה דיגיטלית לילדים',
        //     subTitle: 'בצבעים כחול או ורוד',
        //     desc: '29 ש"ח במקום 79 ש"ח',
        //     img: cam,
        //     color: "var(--primary)",
        //     bg: "var(--secondary)"
        // },
        // {
        //     title: "מנצחים גם בדרך...",
        //     subTitle: 'קפה ומאפה',
        //     desc: '15 ש"ח במקום 19 ש"ח ',
        //     img: yellow,
        //     color: "white",
        //     bg: "var(--bg)"
        // },
        // {
        //     title: "GREEN GAS",
        //     subTitle: 'פטריות חימום גז',
        //     desc: '539 ש"ח במקום 699 ש"ח ',
        //     img: gas,
        //     color: "var(--primary)",
        //     bg: "var(--secondary)"
        // },
        // {
        //     title: "DALITEX",
        //     subTitle: "פופ ישיבה כדורגל",
        //     desc: '199 ש"ח במקום 329 ש"ח ',
        //     img: puf,
        //     color: "white",
        //     bg: "var(--bg)"
        // },
    ]

    const couponsPending = useSelector(state => state.coupon.isCouponsPending)
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(getCoupons())
    }, [dispatch])

    if (couponsPending) return <CircularProgress style={{ display: 'block', margin: '20px auto' }} />

    return (
        <div className='caseback-center'>
            <Swiper
                speed={750}
                freeMode={true}
                slidesPerView={couponsSlidesPerView}
                style={{ width: "auto" }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // pagination={{
                //   clickable: true,
                // }}
                modules={[Autoplay, Pagination]}
                // breakpoints={{
                //     // when window width is >= 600px
                //     600: {
                //         slidesPerView: 2,
                //     },
                //     // when window width is >= 900px
                //     900: {
                //         slidesPerView: 3,
                //     },
                // }}
                // slidesPerView={"auto"}
                spaceBetween={10}
            // style={{ direction: "ltr" }}
            >
                {couponsArr
                    .map((coupon, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                style={{ width: "auto" }}
                            >
                                <Coupon
                                    desc={coupon.desc}
                                    img={coupon.img}
                                    bg={coupon.bg}
                                    color={coupon.color}
                                    title={t(coupon.title)}
                                    subTitle={coupon.subTitle}
                                    discountValue={coupon.discountValue}
                                    discountType={coupon.discountType}
                                    key={coupon.id}
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>

    )
}

export default CouponsList